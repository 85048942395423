<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <form>
          <h1>สร้างรายการ Pompay</h1>

          <div class="my-5">
            <vs-input  class="w-full mt-4" label="username" v-model="username"
            type="text" name="username" />
            <vs-input  class="w-full mt-4" label="เครดิต" v-model="amount"
              type="number" name="amount" />
          </div>

          <vs-button
            :disabled="!$store.state.AppActiveUser.permissions.payment.action"
            color="primary"
            type="filled"
            @click="() => generateQR('qr')"
          >
            {{ result ? 'สร้าง QR ใหม่' : 'สร้าง QR' }}
          </vs-button>

          <vs-button
            :disabled="!$store.state.AppActiveUser.permissions.payment.action"
            color="primary"
            type="filled"
            class="ml-5"
            @click="() => generateQR('bankTransfer')"
          >
            {{ result ? 'สร้างเลขบัญชีใหม่' : 'สร้างเลขบัญชี' }}
          </vs-button>


          <div v-if="result">
              <vs-divider />
              <vs-input  class="w-full mt-4" label="username" v-model="result.data.username"
              type="text" name="username" readonly />
              <vs-input  class="w-full mt-4" label="เครดิต" v-model="result.data.amount"
                type="number" name="amount" readonly />
              <vs-input
                class="w-full my-2"
                label="รหัสอ้างอิง"
                readonly
                v-model="result.data.qrCodeData.qrCode.referenceId"
                type="text"
                name="amount"
              />
              <vs-input
                class="w-full my-2"
                label="รหัสธุรกรรม"
                readonly
                v-model="result.data.qrCodeData.order_id"
                type="text"
                name="amount"
              />
              <img v-if="result.data.qrCodeData.qrType == 'qr'" :src="result.data.qrCodeData.qrCode" />
              <div v-else>
                <vs-input
                class="w-full my-2"
                label="เลขบัญชี"
                readonly
                v-model="result.data.qrCodeData.qrCode.bankAccount"
                type="text"
              />
              <vs-input
                class="w-full my-2"
                label="ชื่อบัญชี"
                readonly
                v-model="result.data.qrCodeData.qrCode.bankAccountName"
                type="text"
              />
              <vs-input
                class="w-full my-2"
                label="ธนาคาร"
                readonly
                v-model="result.data.qrCodeData.qrCode.bankName"
                type="text"
              />
              </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  components: {
  },
  data () {
    return {
      amount: null,
      username: null,
      result: null
    }
  },
  computed: {

  },
  watch: {

  },
  async mounted () {

  },
  methods: {
    async generateQR (paymentMethod = 'qr') {
      const result = (await axios
        .post('/pompay/generate', {
          amount: this.amount,
          username: this.username,
          paymentMethod
        })).data

      if (!result.success) {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: result.code,
          text: result.message
        })
        return
      }

      this.$vs.notify({
        time: 10000,
        color: 'success',
        position: 'top-right',
        icon: 'check',
        title: result.code,
        text: result.message
      })

      // Setup data
      this.result = result

      // Clear input data
      this.clearData()
    },
    clearData () {
      this.amount = null
      this.username = null
    }
  }
}
</script>
